@import '../../../scss/theme-bootstrap';

.page-sticky-footer {
  &__inner {
    text-align: center;
    .page-sticky-footer {
      &__left {
        float: none;
        display: inline-block;
        @media #{$medium-up} {
          display: block;
        }
        .node-elc-nodeblock {
          @include swap_direction(padding, 0 8px 0 14px);
          display: inline-block;
          float: none;
          @media #{$medium-up} {
            float: #{$ldirection};
          }
        }
      }
      &__loyalty {
        text-transform: uppercase;
        font-size: 12px;
        letter-spacing: 0.15em;
        font-weight: unset;
        @media #{$medium-up} {
          font-size: 14px;
          letter-spacing: 0.75px;
          font-weight: 700;
        }
        &:link,
        &:visited {
          color: $color-white;
          @media #{$medium-up} {
            color: $color-periwinkle-blue;
          }
        }
        &:hover {
          @media #{$medium-up} {
            color: $color-white;
          }
        }
      }
      &__special_offers__link {
        &:link,
        &:visited {
          color: $color-white;
          @media #{$medium-up} {
            color: $color-periwinkle-blue;
          }
        }
        &:hover {
          @media #{$medium-up} {
            color: $color-white;
          }
        }
      }
    }
  }
  &__right {
    display: inline-block;
  }
  &__livechat {
    @include swap_direction(padding, 11px 8px 0 14px);
    float: #{$ldirection};
    text-transform: uppercase;
    letter-spacing: 0.15em;
    font-size: 12px;
    @media #{$medium-up} {
      @include swap_direction(padding, 0 14px 0 44px);
      font-size: 14px;
      margin-top: 12px;
    }
    a {
      font-weight: normal;
      letter-spacing: 0.15em;
      @media #{$medium-up} {
        font-weight: 700;
        letter-spacing: normal;
      }
      &:link,
      &:visited {
        color: $color-white;
        @media #{$medium-up} {
          color: $color-periwinkle-blue;
        }
      }
      &:hover {
        @media #{$medium-up} {
          color: $color-white;
        }
      }
    }
  }
  &__offers {
    display: inline-block;
    line-height: 18px;
    border-#{$ldirection}: 1px solid $color-mobile-separator;
    font-size: 12px;
    font-weight: normal;
    padding: 0 14px;
    letter-spacing: 0.15em;
    text-transform: uppercase;
    @media #{$medium-up} {
      border: none;
      font-size: 14px;
      font-weight: 700;
      line-height: 23px;
      letter-spacing: 0.75px;
    }
  }
}
